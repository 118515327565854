body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark-mode {
  color: #fff;
  background-color: #313843;
}

.mainbox{
  margin: 0 15% 0 15%;
}



@media only screen and (max-width: 768px) {

  .mainbox{
    margin: 0 5% 0 5%;
  }
}

@media only screen and (max-width: 414px) {

  .mainbox{
    margin: 0 5% 0 5%;
  }
}

@media only screen and (max-width: 320px) {

  .mainbox{
    margin: 0 5% 0 5%;
  }
}

.mainboxArt{
  margin: 0 5% 0 5%;
}

.navtext{
  color: white;
  padding: 0 15px 0 15px;
  }










/* //////////////////////////// */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Navbarholder {
    background: black;
    padding: 30px 0 30px 0;
}

.flexnav{
    display: flex;
    justify-content: space-evenly;
}
  
.logo{
    width: 256px;
    height: auto;
}

nav{
    display: flex;
}

.navlink{
    color: white;
    padding: 15px;
    margin: 10px; 
    border-radius: 2px;
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: bold;
    background:  rgb(95, 95, 95);   
}

.navlink:hover{
     
    background:  red;
}
  
.Navbarholderhalo {
    padding: 7px;
    background: grey;  
}



.navlinkHalo{
    color: white;
    /* border: 2px solid black; */
    padding: 10px 12px 10px 12px;
    margin: 0 15px 0px 15px; 
    border-radius: 5px;
    font-size: .6rem; 
    text-decoration: none;
    font-weight:bold;
    background: rgb(0, 78, 180);
}

.navlinkHalo:hover{
    /* color: white; */
    background: rgb(0, 129, 250);  
}


/* ////////////////////////////////////////////////////////////////////// */
/*////////////////////////// TOGGLE SWITCH for darkmode /////////////////*/
/* ///////////////////////////////////////////////////////////////////// */

.darkModeHolder {
    align-items: center;
    display: flex; 
    /* border: 2px solid orange;  */
  }

.dark-mode__toggle {
    background: papayawhip;
    border-radius: 50px;
    border: 1px solid black;
    height: 20px;
    position: relative;
    width: 40px;
  }
  
  .toggle {
    background: #f68819;
    border-radius: 50px;
    height: 20px;
    left: 0;
    position: absolute;
    transition: 0.2s;
    width: 22px;
  }
  
  .toggled {
    left: 18px;
  }

  .navbar2b {
    background-color: #1F2022;
    border: none;
  }
@media only screen and (max-width: 500px) {
    .Navbarholder{
        padding: 10px 0 10px 0;
    }
}



@media only screen and (max-width: 960px) {
    .flexnav{
        display: flex; 
        flex-wrap: wrap;  
    }
}

@media only screen and (max-width: 500px) {
    .logo{
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 960px) {
    .navlink{ 
        color: white;
        padding: 15px;
        margin: 10px; 
        border-radius: 2px;
        font-size: 1rem;
        text-decoration: none;
        font-weight: bold;
        background:  rgb(95, 95, 95);   
    }
}




@media only screen and (max-width: 500px) {

    .navlink{
        color: white;
        padding: 8px;
        margin: 0 8px 0 8px; 
        border-radius: 2px;
        font-size: .8rem;
        text-decoration: none;
        font-weight: bold;
        background:  rgb(95, 95, 95);
    }
}

@media only screen and (max-width: 320px) {

    .navlink{
        color: white;
        padding: 6px;
        margin: 0 4px 0 4px; 
        border-radius: 2px;
        font-size: .75rem;
        text-decoration: none;
        font-weight: bold;
        background:  rgb(95, 95, 95);
    }
}
.footerholder {
    padding: 15px 0 15px 0;
    justify-content: center;
    position: relative;
    margin-top: 8%;
    /* bottom: 0; */
    /* width: 100%; */
    /* height: 2.5rem; */
    background: black;
}

@media only screen and (max-width: 500px) {
    .footerholder{
      justify-content: center;
      position: relative;
      /* bottom: 0; */
      /* width: 100%; */
      /* height: 2.5rem; */
    }
  }

.btn{
    color: white;
    padding: 12px 12px 12px 12px;
    margin: 0 10px 10px 10px; 
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    background:  rgb(70, 70, 70);
}

.btn:hover{
    background:  red;
}



.imagdefault{
    height: 256px;
    width: 256px;  
    border: 2px solid black;    
}

.margintext2{
    font-weight: 700;
    
}

.ulholder1{
    display: flex;
    width: 600px;
}
.ulholder2{
    display: none;
}

@media only screen and (max-width: 425px) {
    .ulholder1{
        display: none; 
    }
    .ulholder2{
      margin: 0 5% 0 5%;
      display: flex; 
      width: 250px;
    }
}

@media only screen and (max-width: 375px) {
    
    .ulholder1{
        display: none; 
    }
    .ulholder2{
      margin: 0 5% 0 5%;
      display: flex; 
      width: 250px;
    }
  }

@media only screen and (max-width: 320px) {

    .ulholder1{
      display: none; 
  

    }
    .ulholder2{
        display: flex; 
        width: 250px;
      }
  }

.litext{
    text-align: left;
    margin: 5px 0 0 0;
    font-size: 14px;
}

/* BUllet Point */
li{ 
    padding: 1px;    
}

.textgold{
    color: rgb(204, 133, 0);
}

/* ABOUT ME - Section 1 */


/* IMAGE BEN AND KAYLEE */
.section1{
    display:flex;
}

    @media only screen and (max-width: 500px) {
        .section1{
        flex-direction: column;
        display: block;
        }
    }

.name{
    display:flex;
    justify-content: center;
}

.h2text{
    font-style: italic;
    font-weight: 400;
    padding-top: 10px;
    padding-left: 30px;
}

h2{
    font-weight: 400;  
}

    @media only screen and (max-width: 500px) {
        .name{
            display:block;
        }
    }


.imgBenKaylee{
    height:auto;
    width: 300px;
    border-radius: 5px;
    padding: 0 0 10px 0;
}

    @media only screen and (max-width: 800px) {
        .imgBenKaylee{
        height:auto;
        width: 320px; 
        }
    }

    @media only screen and (max-width: 500px) {
            .imgBenKaylee{
            height:auto;
            width: 256px;
            }
        }

/* END BEN AND KAYLEE */

.textholder1{
    padding: 0 20px 0 20px;
}
 

/* SECTION 2 */
.sectionContact{
    display:flex;
    justify-content: space-between;
    border-top: 3px solid black;
}


    @media only screen and (max-width: 500px) {
    
        .sectionContact{
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 0 15px 0;
            border-top: 2px solid black;   
        }
    }

    @media only screen and (max-width: 320px) {

        .sectionContact{
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 5px;
            padding: 10px 0 10px 0;
            border-top: 2px solid black;    
        }
    }



.myImgs{
    height:auto;
    /* width: 256px; */
    width: 256px;
    margin: 5px;
    border: 2px solid black;
}

.myVideos{
    /* height:auto; */
    /* width: 256px; */
    /* width: 256px; */
    margin: 5px;
    border: 2px solid black;
}

.glyphicon{
    background:black;
}
.Holder{
    /* display: flex; */
  
  border: 2px solid black;
}

/* //////////////////////// */

.myImgs:hover{
    cursor: pointer;
    border: 2px solid red;
}

.myImgsModal{
    height:auto;
    width: 612px;
}

.closeholder{
    display: flex;
    justify-content: center;
    border: 2px solid black;
    width: 35px;
    font-size: 1.5rem; 
    font-weight: bold; 
    padding: 5px; 
    background: rgb(134, 134, 134);  
}

.closeholder:hover{
    background: red;
    cursor: pointer;
}


/* MODAL - pop up window for artwork */

.modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}

    
  
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 45%; 
}

 
@media only screen and (max-width: 800px) {

    .myImgsModal{
        height:auto;
        width: 512px;
    }
}


@media only screen and (max-width: 500px) {

    .myImgsModal{
        display: none;
    }
}


@media only screen and (max-width: 500px) {

    .modal{
        display: none;
        background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0);
    }
}

@media only screen and (max-width: 1024px) {
    .modal-content {
        background-color: #fefefe;
        margin: 15% auto; 
        padding: 10px;
        border: 1px solid #888;
        width: 60%; 
      }  
}


@media only screen and (max-width: 800px) {
    .modal-content {
        background-color: #fefefe;
        margin: 15% auto; 
        padding: 20px;
        border: 1px solid #888;
        width: 65%; 
      }   
}


@media only screen and (max-width: 500px) {

    .modal-content{
        display: none;
        background-color: none;
        background-color: none;
    }
}
.title{
    margin: 5px 0 5px 0;
    display: flex;
    justify-content: left;
    /* background: rgb(97, 97, 97); */  
}

/* Iphone 6/7/8 Plus*/
@media only screen and (max-width: 425px) {
    .title{
        display:flex;
        flex-direction: column;
    }
    h1 {
        margin-bottom: 0;
    }
    h3 {
        margin: 6px 0;
    }
}

.Conwaylink{
color: white;
}

.btn2Holder{
    display: flex;
}

.btn2{
    height:50px;
    color: white;
    width: 100px;
    padding: 10px;
    margin: 10px; 
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    background:  rgb(70, 70, 70);
}

.btn2:hover{
    background:  red;
}



.btnGit{
    width: 200px;
    color: white;
    padding: 12px 12px 12px 12px;
    margin: 10px 0 10px 25px; 
    border-radius: 2px;
    font-size: 1.2rem;
    font-weight: bold;
    background:  rgb(70, 70, 70);
}

.btnGit:hover{
    background:  red;
}

/* CODE PROJECTS */
.projectcontainer{
    justify-content: center;
    display: flex;
    flex-wrap:wrap; 
}



/* PROJECT HALO */
.projectHalo{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(/static/media/api-halo5.f2bceb3a.jpg);
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectHalo{
        width: 100%;
        height: 300px; 
        border-radius: 5px;
    }
}


/* PROJECT CIPHER */
.projectCipher{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(/static/media/MyCipher.3e5cab0e.PNG);
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectCipher{
        width: 100%;
        height: 300px; 
        border-radius: 5px;
    }
}


/* PROJECT GROA */
.projectGroa{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(/static/media/Groa.769683c1.jpg);
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectGroa{
        width: 100%;
        height: 300px;  
        border-radius: 5px;
    }
}


/* PROJECT CONWAY */
.projectConway{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(/static/media/ConwayLife.ac4a6c82.PNG);
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectConway{
        width: 100%;
        height: 300px;  
        border-radius: 5px;
    }
}

.projectUnreal{
    margin: 1%;
    padding: 12px;
    background: rgb(97, 97, 97);
    width: 40%;
    height: 280px; 
    border-radius: 5px;
}

.videoSize{
    width: 266px;
    height: 138px;
}

/* Ipad */
@media only screen and (max-width: 768px) {
    .projectUnreal{
        width: 40%;
        height: 280px; 
        border-radius: 5px;
    }
    .videoSize{
        width: 266px;
        height: 138px;
    }
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectUnreal{
        width: 100%;
        height: 300px; 
        border-radius: 5px;
    }
}





/* 320- iphone 5s */
@media only screen and (max-width: 320px) {
    .videoSize{
        width:200px;
        height:132px 
    }
  }

.textcolorTitle{
   color: white;   
}

.bullet{
    color: white;   
    font-size: 14px;
 }

.textcolor{
    color: white;
}




