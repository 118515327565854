.myImgs{
    height:auto;
    /* width: 256px; */
    width: 256px;
    margin: 5px;
    border: 2px solid black;
}

.myVideos{
    /* height:auto; */
    /* width: 256px; */
    /* width: 256px; */
    margin: 5px;
    border: 2px solid black;
}

.glyphicon{
    background:black;
}
.Holder{
    /* display: flex; */
  
  border: 2px solid black;
}

/* //////////////////////// */

.myImgs:hover{
    cursor: pointer;
    border: 2px solid red;
}

.myImgsModal{
    height:auto;
    width: 612px;
}

.closeholder{
    display: flex;
    justify-content: center;
    border: 2px solid black;
    width: 35px;
    font-size: 1.5rem; 
    font-weight: bold; 
    padding: 5px; 
    background: rgb(134, 134, 134);  
}

.closeholder:hover{
    background: red;
    cursor: pointer;
}


/* MODAL - pop up window for artwork */

.modal {
    display: none; 
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4); 
}

    
  
.modal-content {
    background-color: #fefefe;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
    width: 45%; 
}

 