.footerholder {
    padding: 15px 0 15px 0;
    justify-content: center;
    position: relative;
    margin-top: 8%;
    /* bottom: 0; */
    /* width: 100%; */
    /* height: 2.5rem; */
    background: black;
}

@media only screen and (max-width: 500px) {
    .footerholder{
      justify-content: center;
      position: relative;
      /* bottom: 0; */
      /* width: 100%; */
      /* height: 2.5rem; */
    }
  }
