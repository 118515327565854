.dark-mode {
  color: #fff;
  background-color: #313843;
}

.mainbox{
  margin: 0 15% 0 15%;
}



@media only screen and (max-width: 768px) {

  .mainbox{
    margin: 0 5% 0 5%;
  }
}

@media only screen and (max-width: 414px) {

  .mainbox{
    margin: 0 5% 0 5%;
  }
}

@media only screen and (max-width: 320px) {

  .mainbox{
    margin: 0 5% 0 5%;
  }
}

.mainboxArt{
  margin: 0 5% 0 5%;
}

.navtext{
  color: white;
  padding: 0 15px 0 15px;
  }










/* //////////////////////////// */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
