.btn{
    color: white;
    padding: 12px 12px 12px 12px;
    margin: 0 10px 10px 10px; 
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    background:  rgb(70, 70, 70);
}

.btn:hover{
    background:  red;
}



.imagdefault{
    height: 256px;
    width: 256px;  
    border: 2px solid black;    
}

.margintext2{
    font-weight: 700;
    
}

.ulholder1{
    display: flex;
    width: 600px;
}
.ulholder2{
    display: none;
}

@media only screen and (max-width: 425px) {
    .ulholder1{
        display: none; 
    }
    .ulholder2{
      margin: 0 5% 0 5%;
      display: flex; 
      width: 250px;
    }
}

@media only screen and (max-width: 375px) {
    
    .ulholder1{
        display: none; 
    }
    .ulholder2{
      margin: 0 5% 0 5%;
      display: flex; 
      width: 250px;
    }
  }

@media only screen and (max-width: 320px) {

    .ulholder1{
      display: none; 
  

    }
    .ulholder2{
        display: flex; 
        width: 250px;
      }
  }

.litext{
    text-align: left;
    margin: 5px 0 0 0;
    font-size: 14px;
}

/* BUllet Point */
li{ 
    padding: 1px;    
}

.textgold{
    color: rgb(204, 133, 0);
}

/* ABOUT ME - Section 1 */


/* IMAGE BEN AND KAYLEE */
.section1{
    display:flex;
}

    @media only screen and (max-width: 500px) {
        .section1{
        flex-direction: column;
        display: block;
        }
    }

.name{
    display:flex;
    justify-content: center;
}

.h2text{
    font-style: italic;
    font-weight: 400;
    padding-top: 10px;
    padding-left: 30px;
}

h2{
    font-weight: 400;  
}

    @media only screen and (max-width: 500px) {
        .name{
            display:block;
        }
    }


.imgBenKaylee{
    height:auto;
    width: 300px;
    border-radius: 5px;
    padding: 0 0 10px 0;
}

    @media only screen and (max-width: 800px) {
        .imgBenKaylee{
        height:auto;
        width: 320px; 
        }
    }

    @media only screen and (max-width: 500px) {
            .imgBenKaylee{
            height:auto;
            width: 256px;
            }
        }

/* END BEN AND KAYLEE */

.textholder1{
    padding: 0 20px 0 20px;
}
 

/* SECTION 2 */
.sectionContact{
    display:flex;
    justify-content: space-between;
    border-top: 3px solid black;
}


    @media only screen and (max-width: 500px) {
    
        .sectionContact{
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 15px 0 15px 0;
            border-top: 2px solid black;   
        }
    }

    @media only screen and (max-width: 320px) {

        .sectionContact{
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 5px;
            padding: 10px 0 10px 0;
            border-top: 2px solid black;    
        }
    }


