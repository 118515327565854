.title{
    margin: 5px 0 5px 0;
    display: flex;
    justify-content: left;
    /* background: rgb(97, 97, 97); */  
}

/* Iphone 6/7/8 Plus*/
@media only screen and (max-width: 425px) {
    .title{
        display:flex;
        flex-direction: column;
    }
    h1 {
        margin-bottom: 0;
    }
    h3 {
        margin: 6px 0;
    }
}

.Conwaylink{
color: white;
}

.btn2Holder{
    display: flex;
}

.btn2{
    height:50px;
    color: white;
    width: 100px;
    padding: 10px;
    margin: 10px; 
    border-radius: 2px;
    font-size: 1rem;
    font-weight: bold;
    background:  rgb(70, 70, 70);
}

.btn2:hover{
    background:  red;
}



.btnGit{
    width: 200px;
    color: white;
    padding: 12px 12px 12px 12px;
    margin: 10px 0 10px 25px; 
    border-radius: 2px;
    font-size: 1.2rem;
    font-weight: bold;
    background:  rgb(70, 70, 70);
}

.btnGit:hover{
    background:  red;
}

/* CODE PROJECTS */
.projectcontainer{
    justify-content: center;
    display: flex;
    flex-wrap:wrap; 
}



/* PROJECT HALO */
.projectHalo{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../images/api-halo5.jpg');
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectHalo{
        width: 100%;
        height: 300px; 
        border-radius: 5px;
    }
}


/* PROJECT CIPHER */
.projectCipher{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../images/MyCipher.PNG');
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectCipher{
        width: 100%;
        height: 300px; 
        border-radius: 5px;
    }
}


/* PROJECT GROA */
.projectGroa{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../images/Groa.jpg');
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectGroa{
        width: 100%;
        height: 300px;  
        border-radius: 5px;
    }
}


/* PROJECT CONWAY */
.projectConway{
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('../images/ConwayLife.PNG');
    margin: 1%;
    padding: 12px;
    border: 2px solid black;
    width: 40%;
    height: 250px; 
    border-radius: 5px;
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectConway{
        width: 100%;
        height: 300px;  
        border-radius: 5px;
    }
}

.projectUnreal{
    margin: 1%;
    padding: 12px;
    background: rgb(97, 97, 97);
    width: 40%;
    height: 280px; 
    border-radius: 5px;
}

.videoSize{
    width: 266px;
    height: 138px;
}

/* Ipad */
@media only screen and (max-width: 768px) {
    .projectUnreal{
        width: 40%;
        height: 280px; 
        border-radius: 5px;
    }
    .videoSize{
        width: 266px;
        height: 138px;
    }
}

/* Iphone 6/7/8 plus */
@media only screen and (max-width: 425px) {
    .projectUnreal{
        width: 100%;
        height: 300px; 
        border-radius: 5px;
    }
}





/* 320- iphone 5s */
@media only screen and (max-width: 320px) {
    .videoSize{
        width:200px;
        height:132px 
    }
  }

.textcolorTitle{
   color: white;   
}

.bullet{
    color: white;   
    font-size: 14px;
 }

.textcolor{
    color: white;
}



