@media only screen and (max-width: 500px) {
    .Navbarholder{
        padding: 10px 0 10px 0;
    }
}



@media only screen and (max-width: 960px) {
    .flexnav{
        display: flex; 
        flex-wrap: wrap;  
    }
}

@media only screen and (max-width: 500px) {
    .logo{
        padding-bottom: 10px;
    }
}

@media only screen and (max-width: 960px) {
    .navlink{ 
        color: white;
        padding: 15px;
        margin: 10px; 
        border-radius: 2px;
        font-size: 1rem;
        text-decoration: none;
        font-weight: bold;
        background:  rgb(95, 95, 95);   
    }
}




@media only screen and (max-width: 500px) {

    .navlink{
        color: white;
        padding: 8px;
        margin: 0 8px 0 8px; 
        border-radius: 2px;
        font-size: .8rem;
        text-decoration: none;
        font-weight: bold;
        background:  rgb(95, 95, 95);
    }
}

@media only screen and (max-width: 320px) {

    .navlink{
        color: white;
        padding: 6px;
        margin: 0 4px 0 4px; 
        border-radius: 2px;
        font-size: .75rem;
        text-decoration: none;
        font-weight: bold;
        background:  rgb(95, 95, 95);
    }
}